<template>
  <v-container fluid>
    <v-sheet rounded>
      <v-row>
        <v-col>
          <p class="caption ma-0 ml-4">{{$_strings.invoice.INVOICE_NO}} : {{noInvoice ? noInvoice : '-'}}</p>
          <p class="caption ma-0 ml-4">{{$_strings.invoice.ORDER_PERIODE}} : {{invoicePeriod.invoiceGroup}} {{invoicePeriod.startDate }} s/d {{invoicePeriod.endDate}}</p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-end"
        >
          <v-btn
            class="mr-2"
            color="primary"
            small
            @click="updateFixedInvoice"
          >
            {{$_strings.invoice.TITLE_EDIT}}
          </v-btn>
          <v-btn
            v-if="$route.params.companyType === 'shipper'"
            color="primary"
            outlined
            small
            @click="navigateToDetailInvoice"
          >
            {{$_strings.invoice.DETAIL_INVOICE}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :headers="displayedHeaders"
            :items="itemsInvoice"
            :expanded.sync="expanded"
            item-key="limitId"
            show-expand
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            @item-expanded="({value, item}) => value && fetchSubInvoice(item)"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            :loading="isLoading"
            :loading-text="$_strings.order.LOADING_TEXT"
          >
            <template v-slot:[`item.invoiceNo`]={item}>
              <p class="ma-0">{{ item.invoiceNo ? item.invoiceNo : '-' }}</p>
            </template>
            <template v-slot:[`item.price`]={item}>
              <common-text-field-currency
                dense
                outlined
                prefix="Rp"
                class="body-2 pt-2"
                v-model="item.price"
              />
            </template>
            <template
              v-slot:expanded-item="{ headers, item: subItem }"
            >
              <template v-if="subItem.subLoading">
                <td :colspan="headers.length">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-progress-circular
                        class="mx-auto"
                        color="primary"
                        size="30"
                        indeterminate
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-else-if="subItem.subInvoice">
                <td class="white" :colspan="headers.length">
                  <v-container fluid>
                    <v-sheet outlined class="pa-2 rounded">
                      <v-row>
                        <v-col class="pa-1">
                          <v-data-table
                            :headers="headerSubs"
                            calculate-widths
                            hide-default-footer
                            :items="subItem.subInvoice"
                            class="grey lighten-4"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-container>
                </td>
              </template>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>

import {
  thousandSeparated,
  renamePeriode,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  getDate,
} from '@/helper/commonHelpers';

export default {
  name: 'fixed-price-invoice-list-detail',
  created() {
    const { companyType } = this.$route.params;
    this.companyType = companyType;
    this.updateHeaders();
  },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limitPerNopol',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_RITASE,
          value: 'ritaseUsage',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.mou.LICENSE_PLATE,
          value: 'vehicleNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.mou.PRICE,
          value: 'price',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
      ],
      headerSubs: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation',
          class: 'black--text amber accent-3',
          width: '180px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          width: '200px',
        },
        {
          text: this.$_strings.order.DRIVER_NAME,
          value: 'driverName',
          class: 'black--text amber accent-3',
          width: '150px',
        },
      ],
      itemsInvoice: [],
      itemSubInvoice: [],
      search: '',
      noInvoice: '',
      invoicePeriod: {
        endDate: '',
        invoiceGroup: '',
        startDate: '',
      },
      invoiceListTotalEntry: 0,
      pagination: defaultPagination(),
      expanded: [],
      isLoading: false,
      subLoading: false,
    };
  },
  computed: {
    displayedHeaders() {
      let cellClass = null;
      const { companyType } = this.$route.params;
      if (companyType === 'shipper') cellClass = null;
      return this.headers.map((h) => ({
        ...h,
        cellClass,
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchInvoice();
      },
      deep: true,
    },
  },
  methods: {
    async fetchInvoice() {
      this.expanded = [];
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      try {
        const { invoiceId } = this.$route.params;
        this.isLoading = true;
        const result = await this.$_services.invoice.listDetailFixedPriceInvoice(filters, invoiceId);
        this.itemsInvoice = result.data.limitList.contents;
        this.noInvoice = result.data.invoiceNo;
        this.invoicePeriod.invoiceGroup = this.renamePeriode(result.data.invoicePeriod.invoiceGroup);
        this.invoicePeriod.startDate = this.getDate(result.data.invoicePeriod.startDate);
        this.invoicePeriod.endDate = this.getDate(result.data.invoicePeriod.endDate);
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    renamePeriode,
    fetchSubInvoice(invoice) {
      if (invoice.subInvoice) return;
      invoice.subLoading = true;
      const { companyType } = this.$route.params;
      this.$_services.invoice.listFixedPriceLimitInvoice(invoice.invoiceId, invoice.limitId, companyType)
        .then((res) => {
          const contents = res.data.contents.map((i) => ({ ...i }));
          invoice.subLoading = false;
          this.$set(invoice, 'subInvoice', contents);
        }).finally(() => {
          invoice.subLoading = false;
        });
    },
    updateHeaders() {
      if (this.companyType === 'transporter') {
        this.headerSubs.unshift({
          text: this.$_strings.invoice.CARGO_NUMBER,
          value: 'shipmentCargoTitle',
          class: 'black--text amber accent-3',
          width: '150px',
          cellClass: 'clickable',
        });
      } else {
        this.headerSubs.unshift({
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'black--text amber accent-3',
          width: '150px',
          cellClass: 'clickable',
        });
      }
    },
    refactorForm(event) {
      const price = event.replace(/[^,\d]/g, '');
      return price;
    },
    navigateToDetailInvoice(item) {
      this.$router.push({
        name: 'invoice-fixed-price-list-price-detail',
        params: {
          ...this.$route.params,
        },
        query: {
          invoiceNo: this.noInvoice || '-',
          ...this.invoicePeriod,
        },
      });
      // const { companyType } = this.$route.params;
      // if (companyType === 'shipper') {
      // }
    },
    async updateFixedInvoice() {
      const form = this.itemsInvoice.map((item) => ({
        invoiceId: item.invoiceId,
        invoiceFixedPriceDetailId: item.invoiceFixedPriceDetailId,
        priceUnit: item.price,
      }));
      try {
        this.$root.$loading.show();
        this.$_services.invoice.updateFixedPriceInvoice(form);
        this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    getDate,
  },
};
</script>

<style lang="scss" scoped>
.input-price{
  top: 15px;
  width: 150px;
  .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: none;
  }
}
/* Chrome, Safari, Edge, Opera */
.input-price ::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input-qty ::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

</style>

<style>
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
